import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotFoundComponent } from './errors/not-found/not-found.component';
import { AppResolver } from './app.resolvers';
import { CategoryResolver } from './category.resolvers';
import { ServerErrorComponent } from './errors/server-error/server-error.component';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: './home/home.module#HomeModule',
    data: {
      title: 'Home'
    },
    resolve: { application: AppResolver, category: CategoryResolver },

  },
  {
    path: 'login',
    loadChildren: './login/login.module#LoginModule',
    data: {
      title: 'Login'
    },
    resolve: { application: AppResolver, category: CategoryResolver },
  },
  {
    path: 'carts',
    loadChildren: './shopping-cart/shopping-cart.module#ShoppingCartModule',
    data: {
      title: 'Shopping Cart'
    },
    resolve: { application: AppResolver, category: CategoryResolver },
  },
  {
    path: 'checkout',
    loadChildren: './checkout/checkout.module#CheckoutModule',
    data: {
      title: 'Shopping Cart'
    },
    resolve: { application: AppResolver, category: CategoryResolver },
  },
  {
    path: 'my-account',
    loadChildren: './my-account/my-account.module#MyAccountModule',
    data: {
      title: 'My Account'
    },
    resolve: { application: AppResolver, category: CategoryResolver },
  },
  {
    path: 'register',
    loadChildren: './register/register.module#RegisterModule',
    data: {
      title: 'Register'
    },
    resolve: { application: AppResolver, category: CategoryResolver },
  },
  {
    path: 'blogs',
    loadChildren: './blogs/blogs.module#BlogsModule',
    data: {
      title: 'Blogs'
    },
    resolve: { application: AppResolver, category: CategoryResolver },
  },
  {
    path: 'products',
    loadChildren: './products/products.module#ProductsModule',
    data: {
      title: 'Products'
    },
    resolve: { application: AppResolver, category: CategoryResolver },
  },
  {
    path: 'aboutus',
    loadChildren: './about/about.module#AboutModule',
    data: {
      title: 'About Us'
    },
    resolve: { application: AppResolver, category: CategoryResolver },
  },
  {
    path: 'privacy-policy',
    loadChildren: './privacy/privacy.module#PrivacyModule',
    data: {
      title: 'Privacy Policy'
    },
    resolve: { application: AppResolver, category: CategoryResolver },
  },
  {
    path: 'returns-refunds',
    loadChildren: './returns/returns.module#ReturnsModule',
    data: {
      title: 'Returns and Refund Policy'
    },
    resolve: { application: AppResolver, category: CategoryResolver },
  },
  {
    path: 'contact-us',
    loadChildren: './contact/contact.module#ContactModule',
    data: {
      title: 'Contact Us'
    },
    resolve: { application: AppResolver, category: CategoryResolver },
  },
  {
    path: 'customer-service',
    loadChildren: './customer-service/customer-service.module#CustomerServiceModule',
    data: {
      title: 'Customer Service'
    },
    resolve: { application: AppResolver, category: CategoryResolver },
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'server-error', component: ServerErrorComponent,
  },
  {
    path: 'not-found', component: NotFoundComponent,
    resolve: { application: AppResolver, category: CategoryResolver }
  },
  { path: '**', redirectTo: '/not-found' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy', scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule],
  providers: [CategoryResolver, AppResolver]
})
export class AppRoutingModule { }
