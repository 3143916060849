import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {

  constructor(private httpClient: HttpClient) {
  }

  public getGlobalSettings() {
    return this.httpClient.get(`/panel/api/u/v1/settings/global`);
  }

  public getAllCategories() {
    return this.httpClient.post(`/panel/api/u/v1/products/categories`, {records: 'all'});
  }

  public subscribeUser(data) {
    return this.httpClient.post(`/panel/api/u/v1/subscribe`, data);
  }
}
