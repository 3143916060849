import { Injectable } from '@angular/core';
import { LocalStorageService } from './local-storage.service';
import {isEmpty, omit} from 'lodash';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CartsService {

  public cartUpdateEvent: BehaviorSubject<any>;
  constructor(private localStorage: LocalStorageService) {
    this.cartUpdateEvent = new BehaviorSubject<any>(true);
  }

  /**
   * addToCart
   */
  public addToCart(items, qty = 1) {

    qty = parseInt(qty.toString(), 10);
    qty = (isNaN(qty) || qty <= 0 ) ? 1 :  qty;
    const cartItems = (this.localStorage.get('CART_DETAILS') || {});
    if (!isEmpty(cartItems) && cartItems.hasOwnProperty(items.ECOM_PRODUCT_ID)) {
        const oldItem = cartItems[items.ECOM_PRODUCT_ID];
        cartItems[items.ECOM_PRODUCT_ID] = {
          items,
          qty: (oldItem.qty + qty)

        };
    } else {
      cartItems[items.ECOM_PRODUCT_ID] = {
        items,
        qty

      };
    }

    this.localStorage.set('CART_DETAILS', cartItems);
    this.cartUpdateEvent.next(true);
  }

  public getCartItems() {
    return this.localStorage.get('CART_DETAILS');
  }

  /**
   * removeCartItem
   */
  public removeCartItem(itemId) {
    let cartItems = (this.localStorage.get('CART_DETAILS') || {});
    if (!isEmpty(cartItems) && cartItems.hasOwnProperty(itemId)) {
        cartItems = omit(cartItems, [itemId]);
        this.localStorage.set('CART_DETAILS', cartItems);
        this.cartUpdateEvent.next(true);
    }
  }

  /**
   * emptyCart
   */
  public emptyCart() {
    this.localStorage.set('CART_DETAILS', {});
    this.cartUpdateEvent.next(true);
  }
}
