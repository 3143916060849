import { Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';

import { Observable, of, EMPTY } from 'rxjs';
import { delay, catchError, map } from 'rxjs/operators';
import { ApplicationService } from './services/application.service';
import { LocalStorageService } from './services/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AppResolver implements Resolve<Observable<any>> {
  constructor( private applicationService: ApplicationService, private router: Router, private localStorageService: LocalStorageService) {}
  resolve(): Observable<any> {
    return this.applicationService.getGlobalSettings().pipe(catchError((error) => {
      this.router.navigate(['/server-error']);
      return of(false);
    })).pipe(map( (response) => {
      this.localStorageService.set('SETTINGS', response['result']); }
    ));
  }
}
