import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { LocalStorageService } from '../../services/local-storage.service';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CartsService } from '../../services/carts.service';
import { Router } from '@angular/router';
declare var jQuery: any;

@Component({
  selector: 'app-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.css']
})
export class MobileMenuComponent implements OnInit, AfterViewInit {

  public settings = {};
  public category = {};
  constructor(private localStorageService: LocalStorageService,
              private toastr: ToastrService,
              private cartService: CartsService,
              private router: Router) { }

  ngOnInit() {
    this.settings = this.localStorageService.get('SETTINGS');
    this.category = this.localStorageService.get('CATEGORIES');

  }
  ngAfterViewInit() {
      /* Mobile menu */
        jQuery("#mobile-menu").mobileMenu({
          MenuWidth: 250,
          SlideSpeed: 300,
          WindowsMaxWidth: 767,
          PagePush: true,
          FromLeft: true,
          Overlay: true,
          CollapseMenu: true,
          ClassName: "mobile-menu"
      });
  }


  onSubmit(searchForm: NgForm) {
    if (searchForm.valid) {
      this.router.navigate(['/products'],  { queryParams: {searchTerm: searchForm.controls.searchTerm.value} } );
      searchForm.reset();
    } else {
      this.toastr.error('', 'Please enter search term');
    }
  }

}
