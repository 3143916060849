import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from '../../services/local-storage.service';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CartsService } from '../../services/carts.service';
declare var jQuery: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, AfterViewInit {

  public settings = {};
  public category = {};
  public cartItems = [];
  public cartItemCount = 0;
  public cartTotal = 0;
  constructor(private localStorageService: LocalStorageService,
              private toastr: ToastrService,
              private cartService: CartsService,
              private router: Router ) { }

  ngOnInit() {
    this.settings = this.localStorageService.get('SETTINGS');
    this.category = this.localStorageService.get('CATEGORIES');
    jQuery(document).ready(() => {
      this.toggleCart();
    });
    this.fetchCart();
  }

  ngAfterViewInit() {

    this.cartService.cartUpdateEvent.subscribe( (data) => {
      this.fetchCart();
    });

  }

  /**
   * removeItemFromCart
   */
  public removeItemFromCart(itemid) {
     this.cartService.removeCartItem(itemid);
     this.toastr.success('', 'Items removed from cart successfully');
  }

  fetchCart() {
    this.cartItems = [];
    this.cartTotal = 0;
    const cartItems = this.cartService.getCartItems();
    for (const itemId in cartItems) {
      if (cartItems.hasOwnProperty(itemId)) {
        this.cartItems.push(cartItems[itemId]);
        this.cartTotal = this.cartTotal + (cartItems[itemId]['items']['ECOM_PRODUCT_PRICE'] *  cartItems[itemId]['qty']);
      }
    }

    this.cartItemCount  = this.cartItems.length;
  }

  onSubmit(searchForm: NgForm) {
    if (searchForm.valid) {
      this.router.navigate(['/products'],  { queryParams: {searchTerm: searchForm.controls.searchTerm.value} } );
      searchForm.reset();
    } else {
      this.toastr.error('', 'Please enter search term');
    }
  }

  toggleCart() {
      jQuery('.top-cart-contain').click(function() {
          jQuery(this).find('.top-cart-content').stop(true, true).toggle();
      });
  }
}
