import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';

declare var jQuery: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent  implements OnInit {
  currentRoute = '';
  public pageClass = '';
  public showMenu = true;
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private titleService: Title) {
    router.events.pipe(filter(event => event instanceof NavigationEnd),
          map((event) => {
            let child = this.activatedRoute.firstChild;
            while (child) {
                if (child.firstChild) {
                    child = child.firstChild;
                } else if (child.snapshot.data &&    child.snapshot.data['title']) {
                    return {event, title: child.snapshot.data['title']};
                } else {
                    return {event};
                }
            }
            return {event};
        }))
      .subscribe((data) => {
        this.showMenu = true;
        const event = data['event'];
        if (data['title']) {
            this.titleService.setTitle(data['title'] + ' | Sine Electricals');
        }
        this.currentRoute = event['url'];
        if (this.currentRoute === '/home') {
          this.slideDownMenu();
        } else {
          this.slideUpMenu();
        }
        switch (this.currentRoute) {
          case '/not-found':
              this.pageClass = 'error-page';
              break;
          case '/products/details':
              this.pageClass = 'category-page';
              break;
          case '/blogs':
              this.pageClass = 'product-page blog-page';
              break;
          case '/server-error':
                  this.showMenu = false;
                  break;
          case '/home':
          case '/':
              this.pageClass = 'cms-index-index cms-home-page home';
              break;
          default:
            this.pageClass = '';
            break;
        }
        console.log(event);
      });
  }

  ngOnInit(): void {
  }


  private slideUpMenu() {
    jQuery('.mega-menu-category').slideUp();
  }
  private slideDownMenu() {
    jQuery('.mega-menu-category').slideDown();
  }
}
