import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from '../../services/local-storage.service';
import {NgForm} from '@angular/forms';
import { ApplicationService } from '../../services/application.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  public emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
  public settings = {};
  constructor(private localStorageService: LocalStorageService, private toastr: ToastrService, private appservice: ApplicationService) { }

  ngOnInit() {
    this.settings = this.localStorageService.get('SETTINGS');
  }

  onSubmit(f: NgForm) {
    if (f.valid) {
      console.log(f);
      this.appservice.subscribeUser(f.value).subscribe( (response) => {
        this.toastr.success('', 'User subscribed Successfully');
        f.reset();
      });
    } else {
      this.toastr.error('', 'Please enter Valid Email Address');
    }
  }
}
